import React from "react";
import { Link } from "react-router-dom";
import bradcrumbbg1 from "../../src/assets/img/breadcrumbbg.png";
const Sitemap = ({ mainContent }) => {
  const bradcrumbbg = {
    backgroundImage: `url(${bradcrumbbg1})`,
  };
  return (
    <>
      <div className="margindiv-header">
        <section className="breadrum-tittle" style={bradcrumbbg}>
          <div className="container">
            <h1>Sitemap</h1>
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <i className="fa fa-location-arrow"></i>
              </li>
              <li>
                <span>Sitemap</span>
              </li>
            </ul>
          </div>
        </section>
        <section className="staticpage sitemapaddpage">
          <div className="container">
            <h2 className="mb-3">Pages</h2>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
              <li>
                <Link to="/product">Products</Link>
              </li>
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms-of-use">Terms of Use</Link>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
};

export default Sitemap;
