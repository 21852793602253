import React, { useEffect, useState } from "react";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOffCanvas = () => {
    setIsOpen(!isOpen);
  };
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("down");
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [isMobileHeaderFixed, setIsMobileHeaderFixed] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const handleScroll = () => {
    const currentScrollPosition = window.scrollY;
    const direction = currentScrollPosition > scrollPosition ? "down" : "up";

    setScrollDirection(direction);
    setScrollPosition(currentScrollPosition);

    if (currentScrollPosition > 60) {
      setIsHeaderFixed(true);
      setIsMobileHeaderFixed(true);
    } else {
      setIsHeaderFixed(false);
      setIsMobileHeaderFixed(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  return (
    <>
      <header
        className={`header dektopheader ${
          isHeaderFixed ? "pxl-header-fixed" : ""
        }`}
      >
        <div className="container">
          <div className="inner-menu">
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Logo" />
              </Link>
            </div>
              <div
                className="pxl-nav-mobile-button pxl-anchor-divider pxl-cursor--cta"
                onClick={toggleOffCanvas}
              >
                <span className="pxl-icon-line pxl-icon-line1"></span>
                <span className="pxl-icon-line pxl-icon-line2"></span>
                <span className="pxl-icon-line pxl-icon-line3"></span>
              </div>
            <nav className={`nav ${isNavOpen ? "active" : ""}`}>
              <ul className={`nav-list ${isNavOpen ? "active" : ""}`}>
                <li className="nav-item">
                  <Link to="/" className="nav-link" onClick={toggleNav}>
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className="nav-link" onClick={toggleNav}>
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link" onClick={toggleNav}>
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/product" className="nav-link" onClick={toggleNav}>
                    Product
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/faq" className="nav-link" onClick={toggleNav}>
                    FAQ
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="#" className="btn" onClick={toggleNav}>
                  QTAG Login <i className="fa fa-arrow-right"></i>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className={`pxl-header-menu ${isOpen ? "show" : ""}`}>
          <div className="pxl-header-menu-scroll">
            <div
              className="pxl-menu-close pxl-hide-xl pxl-close"
              onClick={toggleOffCanvas}
            ></div>
            <div className="pxl-logo-mobile pxl-hide-xl">
              <Link to="" title="Graviton" rel="home">
                <img src={logo} alt="Graviton" />
              </Link>
            </div>
            <nav className="pxl-header-nav">
              <ul className="">
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about" className="nav-link">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/product" className="nav-link">
                    Product
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/faq" className="nav-link">
                    FAQ
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="#" className="btn">
                  QTAG Login <i className="fa fa-arrow-right"></i>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {isOpen && (
          <div
            className="pxl-header-menu-backdrop"
            onClick={toggleOffCanvas}
          ></div>
        )}
      </header>
    </>
  );
};

export default Header;
